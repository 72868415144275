import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {Typography, Button} from '@mui/material';
import Box from '@mui/material/Box';
import {DataGrid, GridActionsCellItem, GridColDef, GridRowId} from '@mui/x-data-grid';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import axiosInstance from '../../../../utils/axios';
import MySwal from '../../../../utils/sweetAlert';
import CustomNoRowsOverlay from '../../../common/components/TableNoDataOverlay';
import {useMission} from '../core/MissionProvider';

type MissionMeetingsProps = {
  missionId: string;
  tableHeight?: number;
  tableHeader?: string;
  hasPermission?: boolean;
};

const AddMissionMeetingModal: React.FC<{
  show: boolean;
  saveMissionMeeting: (meetingName: string, relatedTopic: string) => Promise<void>;
  setCloseModal: () => void;
  meetingName: string;
  relatedTopic: string;
  setMeetingName: React.Dispatch<React.SetStateAction<string>>;
  setRelatedTopic: React.Dispatch<React.SetStateAction<string>>;
}> = ({show, saveMissionMeeting, setCloseModal, meetingName, setMeetingName, relatedTopic, setRelatedTopic}) => {
  const handleClose = () => {
    setMeetingName('');
    setRelatedTopic('');
    setCloseModal();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add a Meeting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='meetingName'>
            <Form.Label>Meeting Name</Form.Label>
            <Form.Control
              type='text'
              value={meetingName}
              onChange={(e) => setMeetingName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='relatedTopic'>
            <Form.Label>Related Topics (Optional)</Form.Label>
            <Form.Control
              type='text'
              value={relatedTopic}
              onChange={(e) => setRelatedTopic(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button color='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button color='primary' onClick={() => saveMissionMeeting(meetingName, relatedTopic)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditMissionMeetingModal: React.FC<{
  show: boolean;
  saveMissionMeeting: (meetingName: string, relatedTopic: string) => Promise<void>;
  setCloseModal: () => void;
  meetingName: string;
  relatedTopic: string;
  setMeetingName: React.Dispatch<React.SetStateAction<string>>;
  setRelatedTopic: React.Dispatch<React.SetStateAction<string>>;
}> = ({show, saveMissionMeeting, setCloseModal, meetingName, setMeetingName, relatedTopic, setRelatedTopic}) => {
  const handleClose = () => {
    setMeetingName('');
    setRelatedTopic('');
    setCloseModal();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Meeting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='meetingName'>
            <Form.Label>Meeting Name</Form.Label>
            <Form.Control
              type='text'
              value={meetingName}
              onChange={(e) => setMeetingName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='relatedTopic'>
            <Form.Label>Related Topics (Optional)</Form.Label>
            <Form.Control
              type='text'
              value={relatedTopic}
              onChange={(e) => setRelatedTopic(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button color='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button color='primary' onClick={() => saveMissionMeeting(meetingName, relatedTopic)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MissionMeetings: React.FC<MissionMeetingsProps> = ({missionId, tableHeight, tableHeader, hasPermission}) => {
  const [missionMeetings, setMissionMeetings] = useState([] as readonly any[]);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editingMeetingId, setEditingMeetingId] = useState<GridRowId | null>(null);
  const [meetingName, setMeetingName] = useState<string>('');
  const [relatedTopic, setRelatedTopic] = useState<string>('');

  const {setLoadingScreen} = useMission();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      try {
        const response = await axiosInstance.get(
          `/api/mission-meetings?populate=missionMeetings,talkingPoints&filters[missionMeetings][id]=${missionId}`
        );
        const meetings = response.data.data.map((meeting) => {
          return {
            ...meeting.attributes,
            talkingPointsSubmitted: meeting.attributes.talkingPoints.data.length,
            id: meeting.id,
          };
        });
        setMissionMeetings(meetings);
      } catch (error) {
        console.error('Error fetching mission data:', error);
      } finally {
        setLoadingScreen(false);
      }
    };

    fetchData();
  }, [missionId, setLoadingScreen]);

  const saveMissionMeetings = async (meetingName: string, relatedTopic: string) => {
    if (editingMeetingId) {
      await axiosInstance.put(`/api/mission-meetings/${editingMeetingId}`, {
        data: {
          name: meetingName,
          relatedTopic: relatedTopic || '',
          weight: '80',
        },
      }).then(() => {
        setMissionMeetings(missionMeetings.map((row) =>
          row.id === editingMeetingId ? { ...row, name: meetingName, relatedTopic } : row
        ));
        MySwal.fire({
          text: 'Meeting Updated',
          timer: 2000,
          icon: 'success',
          showConfirmButton: false,
          showCancelButton: false,
        });
        setOpenEditModal(false);
      });
    } else {
      await axiosInstance
        .post('/api/mission-meetings', {
          data: {
            name: meetingName,
            relatedTopic: relatedTopic,
            weight: '80',
            missionMeetings: missionId,
          },
        })
        .then((res) => {
          const data = res.data.data;
          MySwal.fire({
            text: 'Meeting Added',
            timer: 2000,
            icon: 'success',
            showConfirmButton: false,
            showCancelButton: false,
          });
          setMissionMeetings((prev) => [...prev, {...data.attributes, id: data.id}]);
          setOpenAddModal(false);
        });
    }

    setMeetingName('');
    setRelatedTopic('');
    setEditingMeetingId(null);
  };

  const handleEditClick = (id: GridRowId) => () => {
    const meeting = missionMeetings.find((row) => row.id === id);
    if (meeting) {
      setMeetingName(meeting.name);
      setRelatedTopic(meeting.relatedTopic);
      setEditingMeetingId(id);
      setOpenEditModal(true);
    }
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const result = await MySwal.fire({
      title: 'Are you sure?',
      text: "Members talking points will be deleted. Would you like to proceed? This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      await axiosInstance.delete(`/api/delete-meeting/${id}`).then(() => {
        setMissionMeetings(missionMeetings.filter((row) => row.id !== id));
        MySwal.fire({
          text: 'Meeting Deleted',
          timer: 2000,
          icon: 'success',
          showConfirmButton: false,
          showCancelButton: false,
        });
      });
    }
  };

  const order = [
    "General Secretary To Lam",
    "Prime Minister",
    "State President",
    "National Assembly",
    "Central Party Committee on Economic Affairs",
    "Ministry of Public Security",
    "Ministry of National Defense",
    "Ministry of Planning and Investment",
    "Ministry of Natural Resources and Environment",
    "Ministry of Industry and Trade",
    "Ministry of Information and Communications",
    "Ministry of Finance",
    "State Bank of Vietnam",
    "Ministry of Science and Technology",
    "Ministry of Agriculture and Rural Development",
    "Ministry of Transport",
    "Ministry of Labor, Invalids and Social Affairs",
    "Ministry of Education and Training",
    "Ministry of Culture, Sports and Tourism",
    "Ministry of Justice",
    "U.S. Embassy",
    "Economists Luncheon (World Bank, International Monetary Fund, International Finance Corporation, National Financial Supervisory Commission)",
    "Electricity of Vietnam (EVN)",
    "PetroVietnam (PVN)"
  ];

  //@ts-ignore
  const sortedMissionMeetings = missionMeetings.sort((a, b) => {
    const indexA = order.indexOf(a.name.trim());
    const indexB = order.indexOf(b.name.trim());
    const largeIndex = order.length + 1;
    return (indexA === -1 ? largeIndex : indexA) - (indexB === -1 ? largeIndex : indexB);
  });

  const missionMeetingsWithRowNumber = sortedMissionMeetings.map((meeting, index) => ({
    ...meeting,
    nameWithRelatedTopic: meeting.relatedTopic ? `${meeting.name} - ${meeting.relatedTopic}` : meeting.name,
    rowNumber: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: 'rowNumber',
      headerName: 'Meeting ID',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      flex: 1,
    },
    {
      field: 'nameWithRelatedTopic',
      headerName: 'Meeting Name',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      flex: 2,
    },
    {
      field: 'talkingPointsSubmitted',
      headerName: 'Talking Points Submitted',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      flex: 1,
      getActions: ({id}) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: tableHeight ?? '300px',
        width: '100%',
        marginTop: '5px',
      }}
    >
      <AddMissionMeetingModal
        show={openAddModal}
        saveMissionMeeting={saveMissionMeetings}
        setCloseModal={() => setOpenAddModal(false)}
        meetingName={meetingName}
        setMeetingName={setMeetingName}
        relatedTopic={relatedTopic}
        setRelatedTopic={setRelatedTopic}
      />
      <EditMissionMeetingModal
        show={openEditModal}
        saveMissionMeeting={saveMissionMeetings}
        setCloseModal={() => setOpenEditModal(false)}
        meetingName={meetingName}
        setMeetingName={setMeetingName}
        relatedTopic={relatedTopic}
        setRelatedTopic={setRelatedTopic}
      />
      <div className='d-flex justify-content-between mb-4'>
        <Typography variant='h5'>{tableHeader ?? 'Mission Meetings'}</Typography>
        {hasPermission && (
          <Button color='primary' variant='contained' onClick={() => setOpenAddModal(true)}>
            Add Meeting
          </Button>
        )}
      </div>
      <DataGrid
        sx={{
          fontSize: '12px',
          boxShadow: 2,
        }}
        rows={missionMeetingsWithRowNumber}
        columns={columns}
        hideFooterPagination
        hideFooter
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default MissionMeetings;
