import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../utils/axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Box, Button, Typography, Card, CardContent, CardActions } from '@mui/material';

const CSRTab = ({ missionId, missionName }) => {
  type CSRFileAttributes = {
    name: string;
    ext: string;
    size: number;
    url: string;
  };

  type MemberAttributes = {
    displayName: string;
  };
  
  type CSRData = {
    id: number;
    attributes: {
      missionName: string;
      csrFile: {
        data?: {
          attributes: CSRFileAttributes;
        } | null;
      },
      member: {
        data?: {
          attributes: MemberAttributes;
        } | null;
      };
    };
  };
  
  const [csrs, setCSRS] = useState<CSRData[]>([]);  

  useEffect(() => {
    const fetchCSR = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/member-csrs?filters[missionName]=${missionName}&populate=csrFile,member&pagination[page]=1&pagination[pageSize]=199`
        );
        const missionRegistrations = response.data.data;
        const sortedCSRs = missionRegistrations.sort((a, b) => {
          const nameA = a.attributes.member?.data?.attributes.displayName?.toLowerCase() || "";
          const nameB = b.attributes.member?.data?.attributes.displayName?.toLowerCase() || "";
          return nameA.localeCompare(nameB);
        });
  
        setCSRS(sortedCSRs);
      } catch (error) {
        console.error('Failed to fetch CSR files:', error);
      }
    };
    fetchCSR();
  }, [missionId, missionName]);

  const downloadCSR = (url, filename) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => saveAs(blob, filename))
      .catch(error => console.error('Error downloading file:', error));
  };

  const downloadAllCSR = async () => {
    const zip = new JSZip();
    const folder = zip.folder("CSR_Files");
  
    if (!folder) {
      console.error("Failed to create folder in ZIP.");
      return;
    }
  
    const downloadPromises = csrs.map(async (csr) => {
      const fileData = csr.attributes.csrFile?.data?.attributes;
      if (fileData) {
        const response = await fetch(fileData.url);
        const blob = await response.blob();
        folder.file(fileData.name, blob);
      }
    });
  
    await Promise.all(downloadPromises);
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'CSR_Files.zip');
    });
  };
  

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, marginTop: '5px' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h5">CSR Files</Typography>
        <Button size="large" variant="contained" onClick={downloadAllCSR} disabled={csrs.length === 0}>
          Download All
        </Button>
      </Box>
      {csrs.map((csr) => {
        if (!csr || !csr.attributes) return null; // Ensure data exists

        const fileData = csr.attributes.csrFile?.data?.attributes;
        return fileData ? (
          <Card key={csr.id} sx={{ width: '300px', display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            <CardContent>
              <Typography variant="h6">{csr.attributes.member?.data?.attributes.displayName}</Typography>
              <Typography variant="subtitle2">{fileData.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {fileData.ext.toUpperCase()} - {fileData.size.toFixed(2)} KB
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" onClick={() => downloadCSR(fileData.url, fileData.name)}>
                Download
              </Button>
            </CardActions>
          </Card>
        ) : null;
      })}
    </Box>
  );
};

export default CSRTab;
