import React, {useCallback, useEffect, useRef,useState} from 'react'
import { useLocation } from 'react-router-dom'
import axiosInstance from '../../../../utils/axios'
import {formatDateRangeToYYYYMMDDFormat, formatDateToYYYYMMDDFormat} from '../../../../utils/helper'
import Cards from '../../../common/components/Cards'
import {useAuth} from '../../auth'
import {useMission} from '../core/MissionProvider'
import {KTIcon} from '../../../../_metronic/helpers'
import {TRepView} from '../../../../types/types'

export const RegisteredMission: React.FC<TRepView> = ({ isRepresentativeView }) => {

  const {currentUser} = useAuth()
  const {missions, setMissions, setLoadingScreen, } = useMission()
  const [missionId, setMissionId] = useState<string[]>([])
  const [registeredCount, setRegisteredCount] = useState<number>(0)
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  const fetchMissions = useCallback(
    async (url, setStateCallback, setRegisteredCount) => {
      setLoading(true)
      try {
        setLoadingScreen(true)
        const { data } = await axiosInstance.get(url)
        const filteredData = data.filter((res) => res.isActive);
        setMissionId(filteredData.map((res)=>res.id))      
        setStateCallback(filteredData.map((registration) => ({ ...registration, id: registration.id, missionRegistrations: registration.missionRegistrationsId })))
        const validMissions = filteredData.filter((res) => !res.msEndDate || new Date() <= new Date(res.msEndDate));
        setRegisteredCount(validMissions.map((res)=>res.id).length)
        setLoadingScreen(false)
      } catch (err) {
        setLoadingScreen(false)
        console.log(err)
      } finally {
        setLoading(false)
      }
    },
    [setLoadingScreen]
  )

  useEffect(() => {
    if (!currentUser || !currentUser.id) {
      console.error('User data is not available.')
      return
    }
    const url = `/api/missions/registered/${currentUser.id}`
    fetchMissions(url, setMissions, setRegisteredCount)
  }, [missions.length, location])
   
  return (
    <div className='row mt-10'>
      <div className='d-flex align-items-start mb-0'>
        <KTIcon iconName='notepad-edit' className='fs-1 mr-auto' />
        <div className="ml-auto" style={{ marginLeft: "10px" }}>
          <h2 className='mt-2'>Mission you Registered ({registeredCount})</h2>
        </div>
      </div>
      {loading ? (
          <div className='d-flex justify-content-center align-items-center w-100' style={{height: '200px'}}>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (<div className='d-flex flex-wrap justify-content-start'>
        {missions.length > 0 ?
           missions
            //@ts-ignore
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
           .map((mission, index) => {
            if (missionId.includes(mission.id) && mission.missionRegistrationsId != undefined) {
              return (
                <Cards
                  id={mission.id}
                  regId={mission.missionRegistrationsId[0].id}
                  title={mission.title}
                  duration={formatDateRangeToYYYYMMDDFormat(mission.msStartDate, mission.msEndDate)}
                  registrationDeadline={formatDateToYYYYMMDDFormat(mission.regCloseDate)}
                  registrationStatus={mission.isActive}
                  inProgress={mission.inprogCount}
                  submitted={mission.submCount}
                  approved={mission.apprCount}
                  cardHeight='325px'
                  cardWidth='450px'
                  key={mission.id}
                  isRepresentativeView={isRepresentativeView}
                  isSubmitted={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].isSubmitted !== null ? mission.missionRegistrationsId[0].isSubmitted : false}
                  billingStatus={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].billingStatus !== null ? mission.missionRegistrationsId[0].billingStatus : 0}
                  billingStatusEx={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].billingStatusEx !== null ? mission.missionRegistrationsId[0].billingStatusEx : 0}
                  participantStatus={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].participantStatus !== null ? mission.missionRegistrationsId[0].participantStatus : 0}
                  submittedCSRStatus={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].submittedCSRStatus !== null ? mission.missionRegistrationsId[0].submittedCSRStatus : 0}
                  companyProfileStatus={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].companyProfileStatus !== null ? mission.missionRegistrationsId[0].companyProfileStatus : 0}
                  talkpointsStatus={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].talkpointsStatus !== null ? mission.missionRegistrationsId[0].talkpointsStatus : 0}
                  compDisplayName={mission.missionRegistrationsId !== undefined && mission.missionRegistrationsId[0].compDisplayName !== null ? mission.missionRegistrationsId[0].compDisplayName : 'N/A'}
                  hasCsr={mission.hasCsr}
                  refundCutDate={formatDateToYYYYMMDDFormat(mission.refundCutDate)}
                  missionDetails={mission}
                  timezone_abbrev={mission.timezone_abbrev}
                  msEndDate={mission.msEndDate}
                  regCloseDate={mission.regCloseDate}
                />
              );
            }
  
            return null; // or simply omit this line to return nothing
          }) :
          
          <div className="container d-flex justify-content-center align-items-center" style={{height: "10vh"}}>
          <div className="alert alert-primary mt-10 mx-5" role="alert">
            <h3>No Mission Registered</h3>
          </div>
       </div>
          }
      </div>
        )}
    </div>
  );  
  
}

export default RegisteredMission
